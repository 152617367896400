import React, { useState } from 'react';
import { Download, X } from 'lucide-react';
import axiosInstance from '../../api/axiosInstance';

const GenerateInvoiceModal = ({ isOpen, onClose, invoiceId }) => {
    const [loading, setLoading] = useState(false);
    const [downloadLink, setDownloadLink] = useState(null);
    const [error, setError] = useState(null);

    const generateInvoice = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axiosInstance.post(`/invoices/generate/${invoiceId}`);

            if (response.data && response.data.details) {
                setDownloadLink(response.data.details);

                // Attempt automatic download
                const link = document.createElement('a');
                // link.href = response.data.details;
                link.setAttribute('download', `invoice_${invoiceId}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (err) {
            setError('Failed to generate invoice');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-96 shadow-xl">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-lg font-semibold">Generate Invoice</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X size={20} />
                    </button>
                </div>

                <div className="p-6 text-center">
                    {loading ? (
                        <div className="flex flex-col items-center space-y-4">
                            <div className="animate-spin w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full"></div>
                            <p className="text-gray-600">Generating invoice...</p>
                        </div>
                    ) : downloadLink ? (
                        <div className="space-y-4">
                            <p className="text-green-600">Invoice generated successfully!</p>
                            <a
                                href={downloadLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 flex items-center justify-center space-x-2"
                            >
                                <Download size={16} />
                                <span>Download Invoice</span>
                            </a>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <p className="text-gray-600">Generate PDF invoice?</p>
                            {error && <p className="text-red-500">{error}</p>}
                            <button
                                onClick={generateInvoice}
                                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
                            >
                                Generate
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GenerateInvoiceModal;