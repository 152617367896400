import React, {useEffect, useState} from 'react';
import { X, Send, Mail, ChevronDown, Paperclip } from 'lucide-react';
import axiosInstance from '../../api/axiosInstance';

const EmailInvoiceModal = ({ isOpen, onClose, invoiceId, invoiceData }) => {
    const EMAIL_TEMPLATES = [
        {
            id: 'vehicle_ready',
            title: 'Vehicle Ready for Collection',
            content: `Dear {customerName},

Your vehicle is now ready for collection from our service center. The invoice for the completed work is attached for your reference.

Please visit us at your earliest convenience to pick up your vehicle. Our team looks forward to seeing you soon.

Best regards,
{businessName}`
        },
        {
            id: 'invoice_reminder',
            title: 'Invoice Payment Reminder',
            content: `Dear {customerName},

I hope this email finds you well. This is a friendly reminder about the attached invoice for our recent services.

Kindly review the invoice and process the payment at your earliest convenience. If you have any questions, please don't hesitate to contact us.

Thank you for your prompt attention.

Best regards,
{businessName}`
        },
        {
            id: 'service_feedback',
            title: 'Service Feedback Request',
            content: `Dear {customerName},

Thank you for choosing our service. We hope you are satisfied with the work we've completed on your vehicle.

We would greatly appreciate if you could take a moment to provide feedback about your recent service experience. Your input helps us continually improve our services.

The invoice for the completed work is attached for your records.

Thank you for your trust in us.

Warm regards,
{businessName}`
        },
        {
            id: 'follow_up',
            title: 'Service Follow-up',
            content: `Dear {customerName},

Following our recent service, we wanted to check in and ensure that everything is functioning perfectly with your vehicle.

If you've noticed any issues or have any concerns, please don't hesitate to reach out to us. Your satisfaction is our top priority.

The invoice for the completed work is attached for your reference.

Best regards,
{businessName}`
        },
        {
            id: 'maintenance_recommendation',
            title: 'Recommended Maintenance',
            content: `Dear {customerName},

Based on our recent service of your vehicle, we would like to recommend some upcoming maintenance to ensure optimal performance and longevity.

Please review the attached invoice and consider scheduling a follow-up appointment for the suggested maintenance.

We're committed to keeping your vehicle in top condition.

Best regards,
{businessName}`
        }
    ];

    const [loading, setLoading] = useState(false);
    const [recipientEmail, setRecipientEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [showTemplates, setShowTemplates] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('custom');

    // New state for invoice attachment
    const [attachInvoice, setAttachInvoice] = useState(true);

    const sendInvoiceEmail = async () => {
        try {
            setLoading(true);
            setError(null);
            setSuccess(false);
            invoiceData.invoice_id = invoiceId;

            const emailPayload = {
                recipientEmail,
                message,

                invoice: invoiceData,
                attachInvoice // Include the attachment preference
            };

            await axiosInstance.post('/email/send-invoice', emailPayload);

            setSuccess(true);
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (err) {
            setError('Failed to send email');
        } finally {
            setLoading(false);
        }
    };

    const selectTemplate = (template) => {
        if (template.id === 'custom') {
            setMessage('');
        } else {
            const personalizedTemplate = template.content
                .replace('{customerName}', invoiceData.customer.name || 'Valued Customer')
                .replace('{businessName}', 'Our Business');
            setMessage(personalizedTemplate);
        }
        setSelectedTemplate(template.id);
        setShowTemplates(false);
    };

    useEffect(() => {
        setRecipientEmail(invoiceData.customer.email);
        // eslint-disable-next-line
    }, []);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-[600px] shadow-xl">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-lg font-semibold flex items-center">
                        <Mail size={20} className="mr-2" /> Email Invoice
                    </h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X size={20} />
                    </button>
                </div>

                <div className="p-6 space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Recipient Email
                        </label>
                        <input
                            type="email"
                            value={recipientEmail}
                            onChange={(e) => setRecipientEmail(e.target.value)}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            placeholder="Enter recipient's email"
                        />
                    </div>

                    <div className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            id="attachInvoice"
                            checked={attachInvoice}
                            onChange={() => setAttachInvoice(!attachInvoice)}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label
                            htmlFor="attachInvoice"
                            className="text-sm font-medium text-gray-700 flex items-center"
                        >
                            <Paperclip size={16} className="mr-2" />
                            Attach Invoice
                        </label>
                    </div>

                    <div className="relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Message Template
                        </label>
                        <div
                            onClick={() => setShowTemplates(!showTemplates)}
                            className="w-full p-2 border rounded-md flex justify-between items-center cursor-pointer hover:bg-gray-50"
                        >
                            {selectedTemplate === 'custom'
                                ? 'Custom Message'
                                : EMAIL_TEMPLATES.find(t => t.id === selectedTemplate)?.title}
                            <ChevronDown size={16} />
                        </div>
                        {showTemplates && (
                            <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
                                {[...EMAIL_TEMPLATES, { id: 'custom', title: 'Custom Message' }].map(template => (
                                    <div
                                        key={template.id}
                                        onClick={() => selectTemplate(template)}
                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {template.title}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Message {selectedTemplate !== 'custom' && '(Editable)'}
                        </label>
                        <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="w-full p-2 border rounded-md h-32 focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-y"
                            placeholder={selectedTemplate === 'custom'
                                ? 'Write your custom message'
                                : 'Edit the selected template'}
                        />
                    </div>

                    {error && (
                        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-2 rounded">
                            {error}
                        </div>
                    )}

                    {success && (
                        <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-2 rounded">
                            Invoice email sent successfully!
                        </div>
                    )}

                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 border rounded-md hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={sendInvoiceEmail}
                            disabled={loading || !recipientEmail}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600
                         disabled:bg-gray-300 disabled:cursor-not-allowed
                         flex items-center space-x-2"
                        >
                            {loading ? (
                                <div className="animate-spin w-4 h-4 border-2 border-white border-t-transparent rounded-full"></div>
                            ) : (
                                <Send size={16} />
                            )}
                            <span>Send</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailInvoiceModal;